import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizedHtml',
})

export class SanitizedHtmlPipe implements PipeTransform {
  public sanitized: DomSanitizer;

  constructor(private sanitizer:DomSanitizer) {
    this.sanitized = sanitizer;
  }

  transform(value: any) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
