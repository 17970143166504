import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class myService {
    private mensajero: BehaviorSubject<any> = new BehaviorSubject({
        displayName: '',
        email: '',
        token: '',
        roles: '',
        id: '',
        expiresIn: '',
        imgUrl: ''
    });

    constructor() { }

    // Método público para quien se quiera suscribir a los mensajes
    public escucha(): Observable<any> {
        return this.mensajero.asObservable();
    }

    // Método público para quien quiera emitir un mensaje
    public emite(msj: any): void {
        this.mensajero.next(msj);
    }
}