<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="collapse navbar-collapse">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="mobileMenuSidebarOpen($event, 'overlay-open')" class="sidemenu-collapse">
            <app-feather-icons [icon]="'menu'" [class]="'header-icon'"></app-feather-icons>
          </button>
        </li>
      </ul>
      <ul class="float-start navbar-nav navbar-left">
      </ul>
    </div>
    <div class="collapse navbar-collapse" [ngClass]="isNavbarCollapsed ? '' : 'show'">
      <ul class="nav navbar-nav navbar-right">
        <li class="nav-item user_profile">
          <button mat-button [matMenuTriggerFor]="profilemenu">
            <div class="chip dropdown-toggle" ngbDropdownToggle class="">
              <span>{{ userLogged?.displayName }}</span>
            </div>
          </button>
          <mat-menu #profilemenu="matMenu" class="profile-menu">
            <div class="noti-list">
              <div class="menu">
                <div class="user_dw_menu">
                  <button (click)="perfil()" mat-menu-item> 
                    <app-feather-icons [icon]="'user'" [class]="'user-menu-icons'"></app-feather-icons>Perfil
                  </button>
                  <!-- <button mat-menu-item>
                    <app-feather-icons
                      [icon]="'mail'"
                      [class]="'user-menu-icons'"
                    ></app-feather-icons
                    >Inbox
                  </button>
                  <button mat-menu-item>
                    <app-feather-icons
                      [icon]="'settings'"
                      [class]="'user-menu-icons'"
                    ></app-feather-icons
                    >Settings
                  </button> -->
                  <button mat-menu-item (click)="logout()">
                    <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>Cerrar sesión
                  </button>
                </div>
              </div>
            </div>
          </mat-menu>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>